import { useState } from "react";
import Axios from "axios";
const Dashboard = () => {
    const [Cars, setCars] = useState([]);
    const getCars = async () => {
        const res = await Axios.get('/car/get');
        setCars(res.data);
    }
    useState(() => {
        getCars();
    }, []);

    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dashboard</h1>
                <a href="/new-car" type="button" className="btn btn-sm btn-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                    New Car
                </a>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Model</th>
                            <th scope="col">Year</th>
                            <th scope="col">Mileage</th>
                            <th scope="col">Fuel Type</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Cars.map((car, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{car.car_name}</td>
                                <td>{car.model_name}</td>
                                <td>{car.year_release}</td>
                                <td>{car.mileage}</td>
                                <td>{car.fuel_type}</td>
                                <td>
                                    <button type="button" className="btn btn-danger"
                                        onClick={() => {
                                            Axios.post(`/car/delete/${car.car_id}`);
                                            getCars();
                                        }}
                                    >Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
}

export default Dashboard;