import { Link } from "react-router-dom";
import Axios from "axios";

const Login = () => {
    const login = async (e) => {
        e.preventDefault();
        let username = e.target.username.value;
        let password = e.target.password.value;
        Axios.post('/login', { username, password }).then((res) => {
            if (res.data !== "err") {
                window.location.assign("/dashboard");
            } else {
                window.location.assign("/login");
            }
        })
    }

    return (
        <div className="contact-section tab-box">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 col-md-12 bg-img" />
                    <div className="col-lg-5 col-md-12 form-section">
                        <div className="login-inner-form">
                            <div className="details">
                                <Link to="/">
                                    <h2>Dera<span id="logo">van</span></h2>
                                </Link>
                                <h3>Sign Into Your Account</h3>
                                <form onSubmit={login}>
                                    <div className="form-group form-box">
                                        <input type="text" name="username" className="form-control" placeholder="Username" aria-label="username" />
                                    </div>
                                    <div className="form-group form-box">
                                        <input type="password" name="password" className="form-control" autoComplete="off" placeholder="Password" aria-label="Password" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn-md btn-theme w-100">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;