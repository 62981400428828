
import React, { useEffect, useState } from "react";
import Axios from "axios";
import $ from 'jquery';
const NewCar = () => {
    const [Model, setModel] = useState(null);
    const getModels = async () => {
        const res = await Axios.get("/model/get");
        setModel(res.data);
    };
    useEffect(() => {
        getModels();
    }, []);

    const upload = () => {
        $('<input type="file" multiple />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await Axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $('#img').append(`<img class="viewImg" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                    })
                })
            }
        })
    }

    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Record New Car</h1>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const src = [];
                $('.viewImg').each(function () {
                    src.push($(this).attr('src'))
                })
                formData.append('src', src);
                const data = Object.fromEntries(formData);
                Axios.post("/car/add", data).then(res => {
                    window.location.assign("/dashboard");
                }).catch(err => {
                    console.log(err)
                });
            }}>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="form-group">
                            <label>Car Name</label>
                            <input type="text" name="car_name" className="form-control" placeholder="Car Name" />
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Car Make</label>
                            <input type="text" name="make" className="form-control" placeholder="Cadilac, Toyota , ..." />
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Car Model</label>
                            <select name="model_id" className="form-select">
                                {Model && Model.map((item, index) => {
                                    return (
                                        <option key={index} value={item.model_id}>{item.model_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Year Release</label>
                            <input type="text" name="year_release" className="form-control" placeholder="Year Release" />
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Car Price</label>
                            <input type="text" name="price" className="form-control" placeholder="Car Price" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="form-group">
                            <label>Body Color</label>
                            <input type="text" name="body_color" className="form-control" placeholder="Car Color" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <label>Interior Color</label>
                            <input type="text" name="interior_color" className="form-control" placeholder="Interior Color" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <label>Select Conditions</label>
                            <select name="condition" className="form-select">
                                <option>New </option>
                                <option>Used</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Mileage</label>
                            <input type="text" name="mileage" className="form-control" placeholder="0" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="form-group">
                            <label>Engine</label>
                            <input type="text" name="engine" className="form-control" placeholder="Engine" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <label>Transmition</label>
                            <select name="transmition" className="form-select">
                                <option>Automatic</option>
                                <option>Manual</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <label>No. Gear</label>
                            <input type="text" name="no_gears" className="form-control" placeholder="No. Gear" />
                        </div>
                    </div>
                    <div className="col-md-2 mb-3">
                        <div className="form-group">
                            <label>Fuel Type</label>
                            <select name="fuel_type" className="form-select">
                                <option>Petrol</option>
                                <option>Diesel</option>
                                <option>Electric</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7 mb-3">
                        <div className="form-group">
                            <label>Car Description</label>
                            <textarea name="description" className="form-control" rows="7"></textarea>
                        </div>
                    </div>
                    <div className="col-md-5 mb-3">
                        <div className="form-group">
                            <label>Car Image</label>
                            <div className="form-control text-center" onClick={upload}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            </div>
                            <br />
                            <div align="center" id="img"></div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <button className="btn btn-primary w-25 pull-right" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </main>
    );
}

export default NewCar;