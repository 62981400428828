import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import $ from "jquery";
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from "./layout/Header";
import NavBar from "./layout/Navbar";
import Footer from "./layout/Footer";
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import CarList from './components/CarList';
import CarDetails from './components/CarDetails';
import Search from './components/Search';
import Login from './components/Login';
import Dashboard from './components/admin/Dashboard';
import HeaderAdm from './components/admin/HeaderAdm';
import SidebarAdm from './components/admin/SidebarAdm';
import Categories from './components/admin/Categories';
import NewCar from './components/admin/NewCar';
import AboutAdm from './components/admin/AboutAdm';
import ContactAdm from './components/admin/ContactAdm';
import CarListID from './components/CartListID';
import Social from './components/admin/Social';


function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<><Header /><NavBar /><Home /> <Footer /></>} />
          <Route exact path="/about" element={<><Header /><NavBar /><About /> <Footer /></>} />
          <Route exact path="/contact" element={<><Header /><NavBar /><Contact /> <Footer /></>} />
          <Route exact path="/gallery" element={<><Header /><NavBar /><CarList /> <Footer /></>} />
          <Route exact path="/model/:id" element={<><Header /><NavBar /><CarListID /> <Footer /></>} />
          <Route exact path="/car-detail/:id" element={<><Header /><NavBar /><CarDetails /> <Footer /></>} />
          <Route exact path="/search/:model_id/:year" element={<><Header /><NavBar /><Search /> <Footer /></>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element={<><HeaderAdm /><SidebarAdm /><Dashboard /></>} />
          <Route exact path="/category" element={<><HeaderAdm /><SidebarAdm /><Categories /></>} />
          <Route exact path="/new-car" element={<><HeaderAdm /><SidebarAdm /><NewCar /></>} />
          <Route exact path="/update-about" element={<><HeaderAdm /><SidebarAdm /><AboutAdm /></>} />
          <Route exact path="/update-contact" element={<><HeaderAdm /><SidebarAdm /><ContactAdm /></>} />
          <Route exact path="/social" element={<><HeaderAdm /><SidebarAdm /><Social /></>} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
