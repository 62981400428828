/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
const Navbar = () => {
    return (
        <nav id="sidebar" className="nav-sidebar">
            <div id="dismiss" onClick={() => { $('#sidebar').removeClass('active'); }}>
                <i className="fa fa-close" />
            </div>
            <div className="sidebar-inner">
                <div className="sidebar-logo">
                    <a href="/">
                        <h2>Dera<span id="logo">van</span></h2>
                    </a>
                </div>
                <div className="sidebar-navigation">
                    <h3 className="heading">Pages</h3>
                    <ul className="menu-list">
                        <li >
                            <Link to="/" onClick={() => { $('#sidebar').removeClass('active'); }}>Home</Link>
                        </li>
                        <li >
                            <Link to="/gallery" onClick={() => { $('#sidebar').removeClass('active'); }}>Gallery</Link>
                        </li>
                        <li >
                            <Link to="/about" onClick={() => { $('#sidebar').removeClass('active'); }}>About</Link>
                        </li>
                        <li >
                            <Link to="/contact" onClick={() => { $('#sidebar').removeClass('active'); }}>Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="get-in-touch">
                    <h3 className="heading">Get in Touch</h3>
                    <div className="get-in-touch-box d-flex mb-2">
                        <i className="flaticon-phone" />
                        <a href="tel:0477-0477-8556-552">0288 2547 874</a>
                    </div>
                    <div className="get-in-touch-box d-flex mb-2">
                        <i className="flaticon-mail" />
                        <div className="media-body">
                            <a href="#">info@themevessel.com</a>
                        </div>
                    </div>
                    <div className="get-in-touch-box d-flex">
                        <i className="flaticon-earth" />
                        <div className="media-body">
                            <a href="#">info@themevessel.com</a>
                        </div>
                    </div>
                </div>
                <div className="get-social">
                    <h3 className="heading">Get Social</h3>
                    <a href="#" className="facebook-bg">
                        <i className="fa fa-facebook" />
                    </a>
                    <a href="#" className="twitter-bg">
                        <i className="fa fa-twitter" />
                    </a>
                    <a href="#" className="google-bg">
                        <i className="fa fa-google" />
                    </a>
                    <a href="#" className="linkedin-bg">
                        <i className="fa fa-linkedin" />
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;