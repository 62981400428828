
import Axios from "axios";
import $ from 'jquery';
import { useState, useEffect } from "react";

const AboutAdm = () => {
    const [about, setAbout] = useState([]);
    useEffect(() => {
        Axios.get("/about/get").then(res => {
            setAbout(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    const upload = () => {
        $('<input type="file" multiple />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await Axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $('#img').append(`<img class="viewImg" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                }
                $('.viewImg').click(function () {
                    $(this).remove()
                })
            })
        })
    }

    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Update About Content</h1>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const src = [];
                $('.viewImg').each(function () {
                    src.push($(this).attr('src'))
                })
                formData.append('src', src);
                const data = Object.fromEntries(formData);
                Axios.post("/about/update", data).then(res => {
                    window.location.assign("/about");
                }).catch(err => {
                    console.log(err)
                });
            }}>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label>About Title</label>
                            <input type="text" name="title" className="form-control" placeholder="title" defaultValue={about.title} />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label>Description</label>
                            <textarea name="content" className="form-control" rows="4" defaultValue={about.content}></textarea>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">
                            <label>Car Image</label>
                            <div className="form-control text-center" onClick={upload}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            </div>
                            <br />
                            <div align="center" id="img">
                                {about.img && about.img.length > 0 ?
                                    <img className="viewImg" src={about.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} />
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <button className="btn btn-primary w-25 pull-right" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </main>
    );
}

export default AboutAdm;