import { Link } from "react-router-dom";
import $ from 'jquery';
import Axios from "axios";
import { useState, useEffect } from "react";


const Header = () => {
    const [contact, setContact] = useState([]);
    useEffect(() => {
        Axios.get("/contact/get").then(res => {
            setContact(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    return (
        <header>
            <header className="top-header bg-active" id="top-header-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-7">
                            <div className="list-inline">
                                <a href={`tel:${contact.phone1}`}><i className="fa fa-phone" /> Need Support? {contact.phone1}</a>
                                <a href={`mailto:${contact.email1}`}><i className="fa fa-envelope" /> {contact.email1}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <header className="main-header sticky-header sh-2">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand company-logo-2" to="/">
                            <img src="/img/logo.png" alt="logo" />
                            {/* <h2>Dera<span id="logo">van</span></h2> */}
                        </Link>
                        <button className="navbar-toggler" type="button" id="drawer" onClick={() => { $('#sidebar').addClass('active'); }}>
                            <span className="fa fa-bars" />
                        </button>
                        <div className="navbar-collapse collapse w-100 justify-content-end" id="navbar">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/gallery">Cars</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/about">About</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </header>

    );
}

export default Header;