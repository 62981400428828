import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
const Search = () => {
    const model_id = useParams().model_id;
    const year = useParams().year;

    const [Model, setModel] = useState(null);
    const getModels = async () => {
        const res = await Axios.get("/model/get");
        setModel(res.data);
    };

    const [Car, setCar] = useState(null);
    const getCars = async () => {
        const res = await Axios.get(`/result/${model_id}/${year}`);
        setCar(res.data);
    };

    const [contact, setContact] = useState([]);
    const getContact = async () => {
        Axios.get("/contact/get").then(res => {
            setContact(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    };

    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/social/get");
        setSocial(res.data[0]);
    };

    useEffect(() => {
        getSocial();
        getModels();
        getCars();
        getContact();
        window.$("#datepicker").datepicker({
            format: "yyyy",
            viewMode: "years",
            minViewMode: "years",
            autoclose: true
        });

    }, []);

    const getReult = (e) => {
        e.preventDefault();
        const model_id = e.target.model.value;
        const year = e.target.datepicker.value;
        window.location.assign(`/search/${model_id}/${year}`);
    };

    return (
        <div className="section">
            <div className="sub-banner">
                <div className="container breadcrumb-area">
                    <div className="breadcrumb-areas">
                        <h1>Search Result</h1>
                        <ul className="breadcrumbs">
                            <li><Link to="index.html">Home</Link></li>
                            <li className="active">Search Result</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="featured-car content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                {Car && Car.map((item, index) => {
                                    return (
                                        <div className="col-lg-6 col-md-6" key={index}>
                                            <div className="car-box-3">
                                                <div className="car-thumbnail">
                                                    <Link to={`/car-detail/${item._id}`} className="car-img">
                                                        <div className="price-box">
                                                            <span>${item.price}</span>
                                                        </div>
                                                        <img className="d-block w-100" src={`../../uploads/${item.src}`} alt="car" />
                                                    </Link>
                                                </div>
                                                <div className="detail">
                                                    <h1 className="title">
                                                        <Link to={`/car-detail/${item.car_id}`}>{item.car_name}</Link>
                                                    </h1>
                                                    <ul className="facilities-list clearfix">
                                                        <li>
                                                            <i className="flaticon-fuel" /> {item.fuel_type}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-way" /> {item.mileage}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-manual-transmission" /> {item.gear}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-gear" /> {item.body_style}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-calendar-1" /> {item.year_release}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar-right">
                                <div className="widget advanced-search2">
                                    <form onSubmit={getReult}>
                                        <h3 className="sidebar-title">Search Cars</h3>
                                        <div className="s-border" />
                                        <div className="m-border" />
                                        <div className="form-group">
                                            <select className="form-select" name="model" id="model">
                                                {Model && Model.map((item, index) => {
                                                    return (
                                                        <option value={item.model_id}>{item.model_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" class="form-control" name="datepicker" id="datepicker" defaultValue="2022" />
                                        </div>
                                        <div className="form-group mb-0">
                                            <button type="submit" className="search-button btn">Search</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="posts-by-category widget">
                                    <h3 className="sidebar-title">Model</h3>
                                    <div className="s-border" />
                                    <div className="m-border" />
                                    <ul className="list-unstyled list-cat">
                                        {Model && Model.map((item, index) => {
                                            return (
                                                <li><a href={`/model/${item.model_id}`}>{item.model_name}</a></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="widget question widget-3">
                                    <h5 className="sidebar-title">Get a Question?</h5>
                                    <div className="s-border" />
                                    <div className="m-border" />
                                    <ul className="contact-info">
                                        <li>
                                            <i className="flaticon-pin" />{contact.address}
                                        </li>
                                        <li>
                                            <i className="flaticon-mail" /><Link to={`mailto:${contact.email1}`}>{contact.email1}</Link>
                                        </li>
                                        <li>
                                            <i className="flaticon-phone" /><Link to={`tel:${contact.phone1}`}>{contact.phone1}</Link>
                                        </li>
                                    </ul>
                                    <div className="social-list clearfix">
                                        <ul>
                                            <li><a href={social && social.facebook} target="_blank" className="facebook-bg"><i className="fa fa-facebook" /></a></li>
                                            <li><a href={social && social.snapchat} target="_blank" className="bg-warning"><i className="fa fa-snapchat" /></a></li>
                                            <li><a href={social && social.youtube} target="_blank" className="google-bg"><i className="fa fa-youtube" /></a></li>
                                            <li><a href={social && social.instagram} target="_blank" className="rss-bg"><i className="fa fa-instagram" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Search;