import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import $ from "jquery";

const Home = () => {
    const [Model, setModel] = useState(null);
    const getModels = async () => {
        const res = await Axios.get("/model/get");
        setModel(res.data);
    };

    const [Car, setCar] = useState(null);
    const getCars = async () => {
        const res = await Axios.get("/car/get");
        setCar(res.data);
        if ($(document).find('.slide').length > 0) {
            function sliderPluggin(activeslide = 0) {
                const slides = document.querySelectorAll('.slide');
                slides[activeslide].classList.add('active');
                function clearActiveClasses() {
                    slides.forEach((slide) => {
                        slide.classList.remove('active');
                    });
                }
                for (const slide of slides) {
                    slide.addEventListener('click', () => {
                        clearActiveClasses();
                        slide.classList.add('active');
                    });
                }
            }
            sliderPluggin(0);
        }
        setTimeout(() => {
            if ($('body .filter-portfolio').length > 0) {
                $(function () {
                    window.$('.filter-portfolio').filterizr(
                        { delay: 0 }
                    );
                });
                $('.filteriz-navigation li').on('click', function () {
                    $('.filteriz-navigation .filtr').removeClass('active');
                    $(this).addClass('active');
                });
            }
        }, 900);
    };

    useEffect(() => {
        getModels();
        getCars();

    }, []);

    return (
        <div className="section">
            <div className="banner-2">
                <div className="slide" style={{ backgroundImage: 'url("img/banner/img-4.jpg")' }}>
                    <div className="breadcrumb-area">
                        <h2>Find your Dream Car</h2>
                        <p>If you want to own a Cadillac One day, stop taking advice from Toyota Drivers.</p>
                        <Link className="btn-3 btn-defaults" to="/gallery">
                            Read More <i className="arrow" />
                        </Link>
                    </div>
                </div>
                <div className="slide" style={{ backgroundImage: 'url(img/banner/img-7.jpg)' }}>
                    <div className="breadcrumb-area">
                        <h2>Welcome to Deravan </h2>
                        <p>Research your next new car with the latest car reviews, news, and model comparisons</p>
                        <Link className="btn-3 btn-defaults" to="/gallery">
                            Read More <i className="arrow" />
                        </Link>
                    </div>
                </div>
                <div className="slide" style={{ backgroundImage: 'url(img/banner/img-5.jpg)' }}>
                </div>
                <div className="slide" style={{ backgroundImage: 'url(img/banner/img-3.jpg)' }}>
                    <div className="breadcrumb-area">
                        <h2>Cadillac </h2>
                        <p>
                            Cadillac is a division of the American automobile manufacturer General Motors (GM) that designs and
                            builds luxury vehicles.
                        </p>
                        <Link className="btn-3 btn-defaults" to="/gallery">
                            Read More <i className="arrow" />
                        </Link>
                    </div>
                </div>
                <div className="slide" style={{ backgroundImage: 'url(img/banner/img-2.jpg)' }}>
                    <div className="breadcrumb-area">
                        <h2>Best Auto Car Now</h2>
                        <a className="btn-3 btn-defaults" href="gallery">
                            Read More <i className="arrow" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="search-box-2">
                <div className="container">

                </div>
            </div>
            <div className="featured-car content-area">
                <div className="container">
                    <div className="main-title mt2">
                        <h1 className="mb-20">Featured Car</h1>
                        <div className="list-inline-listing">
                            <ul className="filters filteriz-navigation clearfix">
                                <li className="active btn filtr-button filtr" data-filter="all"><span>All</span></li>
                                {Model && Model.map((item, index) => {
                                    return (
                                        <li data-filter={item.model_id} key={index} className="btn btn-inline filtr-button filtr">
                                            <span>{item.model_name}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="row filter-portfolio">
                        <div className="cars">
                            {Car && Car.map((item, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-sm-12 filtr-item" data-category={`1, ${item.model_id}`}>
                                        <div className="car-box">
                                            <div className="car-image">
                                                <img className="d-block w-100" src={`../../uploads/${item.src}`} alt="car" />
                                                <div className="tag">${item.price}</div>
                                                <div className="facilities-list clearfix">
                                                    <ul>
                                                        <li>
                                                            <i className="flaticon-way" /> {item.mileage}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-calendar-1" /> {item.year_release}
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-fuel"></i> {item.fuel_type}
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-manual-transmission" /> {item.gear}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="footer clearfix">
                                                <div className="text-center">
                                                    <p className="mt-3">
                                                        <Link className="text-dark" to={`/car-detail/${item.car_id}`}>{item.car_name}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-section-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 align-self-center">
                            {/* Main title */}
                            <div className="main-title">
                                <h1>We Are The Best</h1>
                                <p>
                                    We are the best in the business.
                                </p>
                                <a className="btn-3 btn-defaults none-btn-992" href="/about">
                                    Read More <i className="arrow" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-info">
                                        <div className="number">1</div>
                                        <div className="icon">
                                            <div className="icon-inner">
                                                <i className="flaticon-shield" />
                                            </div>
                                        </div>
                                        <h2>
                                            <a href="services.html">Highly Secured</a>
                                        </h2>
                                        <p>
                                            We are highly secured and we have the best security system in the Iraq.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-info">
                                        <div className="number">2</div>
                                        <div className="icon">
                                            <div className="icon-inner">
                                                <i className="flaticon-deal" />
                                            </div>
                                        </div>
                                        <h2>
                                            <a href="services.html">Trusted Agents</a>
                                        </h2>
                                        <p>
                                            We have the best trusted agents in the Iraq.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-info">
                                        <div className="number">3</div>
                                        <div className="icon">
                                            <div className="icon-inner">
                                                <i className="flaticon-money" />
                                            </div>
                                        </div>
                                        <h2>
                                            <a href="services.html">Get an Offer</a>
                                        </h2>
                                        <p>
                                            We have the best offers in the Iraq.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-info">
                                        <div className="number">4</div>
                                        <div className="icon">
                                            <div className="icon-inner">
                                                <i className="flaticon-support-2" />
                                            </div>
                                        </div>
                                        <h2>
                                            <a href="services.html">Free Support</a>
                                        </h2>
                                        <p>
                                            We have the best support in the Iraq.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="latest-offers-section content-area-13">
                <div className="container">
                    <div className="main-title text-center">
                        <h1>Latest Offers</h1>
                        <p>All New Cadillac Model</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="latest-offers-box">
                                        <div className="latest-offers-box-inner">
                                            <div className="latest-offers-box-overflow">
                                                <div className="latest-offers-box-photo">
                                                    <img className="img-fluid" src="img/latest-offers/img-1.jpg" alt="latest-offers" />
                                                </div>
                                                {/* <div className="info">
                                                    <div className="price-box-2"><sup>$</sup>37,665</div>
                                                    <h3>
                                                        <Link to="/car-detail/1">2022 XT4 FWD LUXURY</Link>
                                                    </h3>
                                                </div> */}
                                                <div className="new">New</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="latest-offers-box">
                                        <div className="latest-offers-box-inner">
                                            <div className="latest-offers-box-overflow">
                                                <div className="latest-offers-box-photo">
                                                    <img className="img-fluid" src="img/latest-offers/img-2.jpg" alt="latest-offers" />
                                                </div>
                                                {/* <div className="info">
                                                    <div className="price-box-2"><sup>$</sup>38,690</div>
                                                    <h3>
                                                        <Link to="/car-detail/1">2022 CT5 RWD LUXURY</Link>
                                                    </h3>
                                                </div> */}
                                                <div className="new">New</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="latest-offers-box">
                                        <div className="latest-offers-box-inner">
                                            <div className="latest-offers-box-overflow">
                                                <div className="latest-offers-box-photo">
                                                    <img className="img-fluid" src="img/latest-offers/img-3.jpg" alt="latest-offers" />
                                                </div>
                                                {/* <div className="info">
                                                    <div className="price-box-2"><sup>$</sup>950<span>/month</span></div>
                                                    <h3 className="category-title">
                                                        <Link to="/car-detail/1">Lamborghini</Link>
                                                    </h3>
                                                </div> */}
                                                <div className="new">New</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="latest-offers-box">
                                <div className="latest-offers-box-inner">
                                    <div className="latest-offers-box-overflow">
                                        <div className="latest-offers-box-photo">
                                            <div className="latest-offers-box-photodd">
                                                <img className="img-fluid big-img" src="img/latest-offers/img-4.jpg" alt="latest-offers" />
                                            </div>
                                        </div>
                                        {/* <div className="info">
                                            <div className="price-box-2"><sup>$</sup>80,065</div>
                                            <h3 className="category-title">
                                                <Link to="/car-detail/1">2022 ESCALADE 2WD LUXURY</Link>
                                            </h3>
                                        </div> */}
                                        <div className="new">New</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-section">
                <div className="container">
                </div>
            </div>
        </div>
    );
}

export default Home;