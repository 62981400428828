/* eslint-disable */
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "axios";

const Footer = () => {
    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/social/get");
        setSocial(res.data[0]);
    };
    useEffect(() => {
        getSocial();
        $('#back').remove();
    }, []);
    return (
        <footer className="main-footer-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo-image">
                            <a href="/">
                                <img src="/img/logo.png" alt="logo" />
                                {/* <h2 style={{ color: "white" }}>Dera<span id="logo">van</span></h2> */}
                            </a>
                        </div>
                        <div className="footer-menu">
                            <ul>
                                <li className="li">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="li">
                                    <Link to="/gallery">Cars</Link>
                                </li>
                                <li className="li">
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className="li">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="social-media social-media-two clearfix">
                            <div className="social-list">
                                <div className="icon facebook">
                                    <div className="tooltip">Facebook</div>
                                    <a href={social && social.facebook} target="_blank"><i className="fa fa-facebook" /></a>
                                </div>
                                <div className="icon bg-warning">
                                    <div className="tooltip">Snapchat</div>
                                    <a href={social && social.snapchat} target="_blank" className="bg-warning"><i className="fa fa-snapchat" /></a>
                                </div>
                                <div className="icon instagram">
                                    <div className="tooltip">Instagram</div>
                                    <a href={social && social.instagram} target="_blank" className="rss-bg"><i className="fa fa-instagram" /></a>
                                </div>
                                <div className="icon youtube">
                                    <div className="tooltip">Youtube</div>
                                    <a href={social && social.youtube} target="_blank" className="google-bg"><i className="fa fa-youtube" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p> © 2022 <a href="http://www.deravan.com/" target="_blank">Deravan Co</a>. All Rights  Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;