
import Axios from "axios";
import { useState, useEffect } from "react";
const ContactAdm = () => {
    const [contact, setContact] = useState([]);
    useEffect(() => {
        Axios.get("/contact/get").then(res => {
            setContact(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Update Contact Content</h1>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = Object.fromEntries(formData);
                Axios.post("/contact/update", data).then(res => {
                    window.location.assign("/contact");
                }).catch(err => {
                    console.log(err)
                });
            }}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Address</label>
                            <input type="text" name="address" className="form-control" placeholder="address" defaultValue={contact.address} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Phone 1</label>
                            <input type="text" name="phone1" className="form-control" placeholder="phone1" defaultValue={contact.phone1} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Phone 1</label>
                            <input type="text" name="phone2" className="form-control" placeholder="phone2" defaultValue={contact.phone2} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Email 1</label>
                            <input type="text" name="email1" className="form-control" placeholder="email1" defaultValue={contact.email1} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Email 1</label>
                            <input type="text" name="email2" className="form-control" placeholder="email2" defaultValue={contact.email2} />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label>Map Link</label>
                            <input type="text" name="map" className="form-control" placeholder="map" defaultValue={contact.map} />
                            <small className="text-muted">Copy <a
                                href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d64458.59881297396!2d42.93265265992981!3d36.705192166181895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40087dce0a27c5e7%3A0xe867423204ff3ca!2sBrad%20Ford%20Company!5e0!3m2!1sen!2siq!4v1651756206676!5m2!1sen!2siq" target={'_blank'}>link</a> from google map</small>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <button className="btn btn-primary w-25 pull-right" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </main>
    );
}

export default ContactAdm;