import { useEffect, useState } from "react";
import Axios from "axios";

const About = () => {
    const [about, setAbout] = useState([]);
    useEffect(() => {
        Axios.get("/about/get").then(res => {
            setAbout(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <div className="section">
            <div className="sub-banner">
                <div className="container breadcrumb-area">
                    <div className="breadcrumb-areas">
                        <h1>About Us</h1>
                        <ul className="breadcrumbs">
                            <li><a href="index.html">Home</a></li>
                            <li className="active">About Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about-car">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="about-slider-box">
                                <img className="d-block w-100" src={about.img} alt="about" />
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="about-text">
                                <h3>
                                    {about.title}
                                </h3>
                                <p>
                                    {about.content}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default About;