import { useState } from "react";
import $ from "jquery";
import Axios from "axios";
const Categories = () => {
    const addModel = (e) => {
        e.preventDefault();
        const model = $('#model').val();
        const res = Axios.post('/model/add', { model });
        if (res.data !== 'err') {
            $('#model').val('');
            getModels();

        }
    }

    const [models, setModels] = useState([]);
    const getModels = async () => {
        const res = await Axios.get('/model/get');
        setModels(res.data);
    }
    useState(() => {
        getModels();

    }, []);


    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dashboard</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newModel">
                        New Model
                    </button>
                    <div class="modal fade" id="newModel" tabindex="-1" aria-labelledby="newModelLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="newModelLabel">Record New Model</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <form onSubmit={addModel}>
                                    <div class="modal-body">
                                        <div class="mb-3">
                                            <label for="model" class="col-form-label">Model:</label>
                                            <input type="text" class="form-control" id="model" />
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Model</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {models.map((model, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{model.model_name}</td>
                                <td>
                                    <button type="button" className="btn btn-danger"
                                        onClick={() => {
                                            Axios.post(`/model/delete/${model.model_id}`);
                                            getModels();
                                        }}
                                    >Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
}

export default Categories;