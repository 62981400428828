import { useState } from "react";
import $ from "jquery";
import Axios from "axios";

const HeaderAdm = () => {
    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }
    useState(() => {
        Axios.get("/auth").then(res => {
            if (res.data === "err") {
                window.location.assign("/login");
            }
        });
        $('#front').remove();
    }, []);
    return (
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/">Deravan</a>
            <div className="navbar-nav">
                <div className="nav-item text-nowrap">
                    <div type="button" className="nav-link px-3" onClick={logout} >Sign out</div>
                </div>
            </div>
        </header>
    );
}

export default HeaderAdm;