import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

const CarDetails = () => {
    const id = useParams().id;
    const [Car, setCar] = useState(null);
    const [Image, setImage] = useState(null);
    const getCars = async () => {
        const res = await Axios.get(`/car/get/${id}`);
        setCar(res.data[0]);
        const res2 = await Axios.get(`/image/get/${id}`);
        setImage(res2.data);
    };

    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/social/get");
        setSocial(res.data[0]);
    };
    const [contact, setContact] = useState([]);

    useEffect(() => {
        Axios.get("/contact/get").then(res => {
            setContact(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
        getCars();
        getSocial();
        setTimeout(() => {
            window.$('.slider-for').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.slider-nav'
            });
            window.$('.slider-nav').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                vertical: true,
                asNavFor: '.slider-for',
                dots: false,
                focusOnSelect: true,
                verticalSwiping: true
            });
        }, 2000);
    }, []);
    return (
        <div className="section">
            <div className="sub-banner">
                <div className="container breadcrumb-area">
                    <div className="breadcrumb-areas">
                        <h1>Car Details</h1>
                        <ul className="breadcrumbs">
                            <li><a href="index.html">Home</a></li>
                            <li className="active">Car Details</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="car-details-page content-area-4">
                <div className="container">
                    {Car && (
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-xs-12">
                                <div className="slide car-details-section cds-2">
                                    <div className="heading-car clearfix">
                                        <div className="pull-left">
                                            <h3>{Car.car_name}</h3>
                                        </div>
                                        <div className="pull-right">
                                            <div className="price-box-3"><sup>$</sup>{Car.price}<span></span></div>
                                        </div>
                                    </div>
                                    <div className="product-slider-box cds-2 clearfix mb-30">
                                        <div className="product-img-slide">
                                            <div className="slider-for">
                                                {Image && Image.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img className="img-fluid w-100" src={`../../uploads/${item.src}`} alt="car" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="slider-nav">
                                                {Image && Image.map((item, index) => {
                                                    return (
                                                        <div className="thumb-slide" key={index}>
                                                            <img src={`../../uploads/${item.src}`} className="img-fluid" alt="small-car" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="car-description mb-50">
                                        <h3 className="heading-2">
                                            Description
                                        </h3>
                                        <p>{Car.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-xs-12">
                                <div className="sidebar-right">
                                    <div className="widget advanced-search d-none-992">
                                        <h3 className="sidebar-title">Specifications</h3>
                                        <div className="s-border" />
                                        <div className="m-border" />
                                        <ul>
                                            <li>
                                                <span>Make</span>{Car.make}
                                            </li>
                                            <li>
                                                <span>Model</span>{Car.model_name}
                                            </li>
                                            <li>
                                                <span>Body Style</span>{Car.body_style}
                                            </li>
                                            <li>
                                                <span>Year</span>{Car.year_release}
                                            </li>
                                            <li>
                                                <span>Condition</span>{Car.conditions}
                                            </li>
                                            <li>
                                                <span>Mileage</span>{Car.mileage}
                                            </li>
                                            <li>
                                                <span>Interior Color</span>{Car.interior_color}
                                            </li>
                                            <li>
                                                <span>Engine</span>{Car.engine}
                                            </li>
                                            <li>
                                                <span>No. of Gears:</span>{Car.no_gears}
                                            </li>
                                            <li>
                                                <span>Fuel Type</span>{Car.fuel_type}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="widget question">
                                        <h5 className="sidebar-title">Get a Question?</h5>
                                        <div className="s-border" />
                                        <div className="m-border" />
                                        <ul className="contact-info">
                                            <li>
                                                <i className="flaticon-pin" /> {contact.address}
                                            </li>
                                            <li>
                                                <i className="flaticon-mail" /> <a href={`mailto:${contact.email1}`}>{contact.email1}</a>
                                            </li>
                                            <li>
                                                <i className="flaticon-phone" /> <a href={`tel:${contact.phone1}`}>{contact.phone1}</a>
                                            </li>
                                        </ul>
                                        <div className="social-list clearfix">
                                            <ul>
                                                <li><a href={social && social.facebook} target="_blank" className="facebook-bg"><i className="fa fa-facebook" /></a></li>
                                                <li><a href={social && social.snapchat} target="_blank" className="bg-warning"><i className="fa fa-snapchat" /></a></li>
                                                <li><a href={social && social.youtube} target="_blank" className="google-bg"><i className="fa fa-youtube" /></a></li>
                                                <li><a href={social && social.instagram} target="_blank" className="rss-bg"><i className="fa fa-instagram" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CarDetails;