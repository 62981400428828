
import Axios from "axios";
import { useState, useEffect } from "react";

const Social = () => {
    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/social/get");
        setSocial(res.data[0]);
    };
    useEffect(() => {
        getSocial();
    }, []);
    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Update Social Links</h1>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = Object.fromEntries(formData);
                Axios.post("/social/update", data).then(res => {
                    window.location.assign("/dashboard");
                }).catch(err => {
                    console.log(err)
                });
            }}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Facebook Url</label>
                            <input type="text" name="facebook" className="form-control" placeholder="Facebook Url" defaultValue={social.facebook} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Instagram Url</label>
                            <input type="text" name="instagram" className="form-control" placeholder="Instagram Url" defaultValue={social.instagram} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Snapchat Url</label>
                            <input type="text" name="snapchat" className="form-control" placeholder="Snapchat Url" defaultValue={social.snapchat} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label>Youtube Url</label>
                            <input type="text" name="youtube" className="form-control" placeholder="Youtube Url" defaultValue={social.youtube} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <button className="btn btn-primary w-25 pull-right" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </main>
    );
}

export default Social;