import { useEffect, useState } from "react";
import Axios from "axios";

const Contact = () => {
    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/social/get");
        setSocial(res.data[0]);
    };

    const [contact, setContact] = useState([]);
    useEffect(() => {
        getSocial();
        Axios.get("/contact/get").then(res => {
            setContact(res.data[0]);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <div className="section">
            <div className="sub-banner">
                <div className="container breadcrumb-area">
                    <div className="breadcrumb-areas">
                        <h1>Contact Us</h1>
                        <ul className="breadcrumbs">
                            <li><a href="index.html">Home</a></li>
                            <li className="active">Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="contact-1 content-area-20">
                <div className="container">
                    <div className="row g-0 contact-innner mb-5">
                        <div className="col-lg-5 col-md-12">
                            <div className="contact-info">
                                <h3 className="mb-20">Contact Info</h3>
                                <div className="ci-box d-flex mb-30">
                                    <div className="icon">
                                        <i className="flaticon-pin" />
                                    </div>
                                    <div className="detail align-self-center">
                                        <h4>Address</h4>
                                        <p> {contact.address} </p>
                                    </div>
                                </div>
                                <div className="ci-box d-flex mb-30">
                                    <div className="icon">
                                        <i className="flaticon-phone" />
                                    </div>
                                    <div className="detail align-self-center">
                                        <h4>Phone Number</h4>
                                        <p>
                                            <a href={`tel:${contact.phone1}`}>{contact.phone1}</a>
                                        </p>
                                        <p>
                                            <a href={`tel:${contact.phone2}`}>{contact.phone2}</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="ci-box d-flex mb-40">
                                    <div className="icon">
                                        <i className="flaticon-mail" />
                                    </div>
                                    <div className="detail align-self-center">
                                        <h4>Email Address</h4>
                                        <p>
                                            <a href={`mailto:${contact.email1}`}>{contact.email1}</a>
                                        </p>
                                        <p>
                                            <a href={`mailto:${contact.email2}`}>{contact.email2}</a>
                                        </p>
                                    </div>
                                </div>
                                <h3 className="mb-20">Follow Us</h3>
                                <div className="social-media social-media-two">
                                    <div className="social-list">
                                        <div className="icon facebook">
                                            <div className="tooltip">Facebook</div>
                                            <a href={social && social.facebook} target="_blank"><i className="fa fa-facebook" /></a>
                                        </div>
                                        <div className="icon bg-warning">
                                            <div className="tooltip">Snapchat</div>
                                            <a href={social && social.snapchat} target="_blank" className="bg-warning"><i className="fa fa-snapchat" /></a>
                                        </div>
                                        <div className="icon instagram">
                                            <div className="tooltip">Instagram</div>
                                            <a href={social && social.instagram} target="_blank" className="rss-bg"><i className="fa fa-instagram" /></a>
                                        </div>
                                        <div className="icon youtube">
                                            <div className="tooltip">Youtube</div>
                                            <a href={social && social.youtube} target="_blank" className="google-bg"><i className="fa fa-youtube" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="mt-5 py-5">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3893.632304363992!2d43.959984330377786!3d36.17205485639635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x65ec56a775f4a35e!2zMzbCsDEwJzExLjciTiA0M8KwNTcnNDQuOCJF!5e0!3m2!1sen!2siq!4v1664883308327!5m2!1sen!2siq"
                                    width={720} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;